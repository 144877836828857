import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getStudyListWithParams } from "api/study";
import { DEFAULT_ROWS_PER_PAGE } from "utils/constants";
import { Study } from "types/models/Study";
import { PaginationParams } from "types/pagination/PaginationParams";
import { logger } from "utils/logger";
import { initStudyDateTime } from "utils/study";
import { PriorityCountByStatus } from "types/PriorityCountByStatus";
import { BaseState, BASE_INITIAL_STATE } from "state/reducers/base";
import { RootState } from "state/store";

export type StudyState = BaseState & {
  list: Study[];
  count: number;
  loadedAt: number | null;
  priorityCountByStatus?: PriorityCountByStatus;
};

export const INITIAL_STATE: StudyState = {
  ...BASE_INITIAL_STATE,
  list: [],
  count: 0,
  loadedAt: null,
  priorityCountByStatus: {},
};

export const getStudyList = createAsyncThunk<
  { studies: Study[]; count: number; priorityCountByStatus?: PriorityCountByStatus },
  PaginationParams,
  { state: RootState }
>("study/list", async (params = {}, { getState }) => {
  if (!params.page_size) {
    params.page_size = DEFAULT_ROWS_PER_PAGE;
  }

  const state = getState();
  const { user } = state.auth;

  const {
    results: studies,
    count,
    priority_count_by_status: priorityCountByStatus,
  } = await getStudyListWithParams({
    expand: "patient,assigned_to",
    procedure_group: user?.procedure_groups,
    insurance_group: user?.insurance_groups,
    hidden: false,
    ...params,
  });

  studies.forEach((study: Study) => initStudyDateTime(study));

  return { studies, count, priorityCountByStatus };
});

export const slice = createSlice({
  name: "study",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getStudyList.fulfilled, (state, { payload }) => {
      state.list = payload.studies;
      state.count = payload.count;
      state.loadedAt = Date.now();
      state.priorityCountByStatus = payload.priorityCountByStatus;
      state.isLoading = false;
    });

    builder.addCase(getStudyList.pending, state => {
      state.isLoading = true;
    });

    builder.addCase(getStudyList.rejected, state => {
      state.isLoading = false;
      logger.error("Failed to fetch studies");
    });
  },
});

export default slice.reducer;
